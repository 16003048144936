const theme = {
  content: ["./node_modules/react-tailwindcss-select/dist/index.esm.js"],
  colors: {
    tint: {
      fuschia: "#CE77F1",
      peach: "#EE67A4",
      blue: "#008EFF",
      orange: "#FF7A00",
    },
    neutral: {
      50: "#FBFBFB",
      200: "#E7E7E7",
      300: "#CFCFCF",
      400: "#B6B6B6",
      500: "#9E9E9E",
      600: "#868686",
      700: "#0F0329",
      800: "#565656",
      900: "#3E3E3E",
    },
    primary: {
      100: "#F8F2FF",
      300: "#E0C9FF",
      500: "#2C0346",
      600: "#2E266F",
      700: "#A100FF",
      800: "#321276",
      900: "#8937F4",
    },
    secondary: {
      100: "#F0F6FF",
      500: "#BED2F5",
      600: "#7DA5EB",
      700: "#3C78E1",
      800: "#1C54B7",
      900: "#123676",
    },
    success: {
      50: "#CEF2DA",
      400: "#3DE274",
      600: "#11C123",
    },
  },
  backgroundImage: {
    "insights-radial":
      "radial-gradient(50% 50.00% at 50% 50.00%, #8937F4 0%, rgba(137, 55, 244, 0.00) 100%)",
    "solutions-radial":
      "radial-gradient(50% 50.00% at 50% 50.00%, #C89EFF 0%, rgba(137, 55, 244, 0.00) 100%)",
    "integrated-radial":
      "radial-gradient(50% 50.00% at 50% 50.00%, #8937F4 0%, rgba(137, 55, 244, 0.00) 100%)",
    "control-radial":
      "radial-gradient(50% 50.00% at 50% 50.00%, #8937F4 0%, rgba(137, 55, 244, 0.00) 100%)",
  },
  boxShadow: {
    "inner-brand": "inset 0px 5.68339px 7.10423px rgba(0, 0, 0, 0.25);",
    "inner-brand-lg": "inset 0px 5.68339px 7.10423px rgba(0, 0, 0, 0.5);",
  },
  fontFamily: {
    montserrat: ["var(--font-montserrat)"],
    inter: ["var(--font-inter)"],
    monda: ["var(--font-monda)"],
  },
  gridTemplateColumns: {
    "col-14": "repeat(14, minmax(0, 1fr))",
  },
};

module.exports = { theme };
